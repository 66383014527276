import { generateEnum, generateInputs, generateInputsChanged, toNoodlFontClass, } from "./boilerplate";
const globalOptions = {
    group: "General Options",
    options: [
        {
            name: "responsive",
            displayName: "Responsive",
            type: "boolean",
            transformFrom: () => true,
        },
        {
            name: "maintainAspectRatio",
            displayName: "Maintain Aspect Ratio",
            type: "boolean",
            transformFrom: () => true,
        },
    ],
};
const tooltipsOptions = {
    group: "Tooltips",
    namespace: "plugins.tooltip",
    options: [
        {
            name: "enabled",
            displayName: "Show Tooltips",
            type: "boolean",
            transformFrom: () => true,
        },
        { name: "intersect" },
        {
            name: "mode",
            type: generateEnum(["point", "nearest", "index", "dataset", "x", "y"]),
        },
        { name: "position", type: generateEnum(["average", "nearest"]) },
        { name: "backgroundColor", type: "color" },
        { name: "bodyColor", type: "color" },
        {
            name: "bodySpacing",
            displayName: "Spacing",
            type: "number",
            transformFrom: () => 2,
        },
        {
            name: "cornerRadius",
            displayName: "Corner Radius",
            type: "number",
            transformFrom: () => 6,
        },
        {
            name: "displayColors",
            displayName: "Display Colors",
            type: "boolean",
            transformFrom: () => true,
        },
    ],
};
const tooltipsFontOptions = {
    group: "Tooltips Font",
    namespace: "plugins.tooltip.bodyFont",
    options: [
        {
            name: "family",
            displayName: "Font Family",
            type: "font",
            transformTo: toNoodlFontClass,
        },
        {
            name: "size",
            displayName: "Font Size",
            type: "number",
            transformFrom: () => 12,
        },
        {
            name: "style",
            displayName: "Font Style",
            type: generateEnum(["normal", "italic", "oblique", "initial"]),
            transformFrom: () => "normal",
        },
        {
            name: "weight",
            displayName: "Weight",
            type: generateEnum(["normal", "italic", "oblique", "initial"]),
            transformFrom: () => "normal",
        },
        {
            name: "lineHeight",
            displayName: "Line Height",
            type: "number",
            transformFrom: () => 1.2,
        },
    ],
};
const titleOptions = {
    group: "Title",
    namespace: "plugins.title",
    options: [
        {
            name: "display",
            displayName: "Show Title",
            type: "boolean",
            transformFrom: () => false,
        },
        { name: "text", type: "string" },
        {
            name: "align",
            type: generateEnum(["start", "center", "end"]),
            transformFrom: () => "center",
        },
        {
            name: "position",
            type: generateEnum(["top", "bottom"]),
            transformFrom: () => "top",
        },
        { name: "color", displayName: "Color", type: "color" },
    ],
};
const titleFontOptions = {
    group: "Title Font",
    namespace: "plugins.title.font",
    options: [
        {
            name: "family",
            displayName: "Font Family",
            type: "font",
            transformTo: toNoodlFontClass,
        },
        {
            name: "size",
            displayName: "Font Size",
            type: "number",
            transformFrom: () => 12,
        },
        {
            name: "style",
            displayName: "Font Style",
            type: generateEnum(["normal", "italic", "oblique", "initial"]),
            transformFrom: () => "normal",
        },
        {
            name: "weight",
            displayName: "Weight",
            type: generateEnum(["normal", "italic", "oblique", "initial"]),
            transformFrom: () => "normal",
        },
        {
            name: "lineHeight",
            displayName: "Line Height",
            type: "number",
            transformFrom: () => 1.2,
        },
    ],
};
const legendOptions = {
    group: "Legend",
    namespace: "plugins.legend",
    options: [
        {
            name: "display",
            displayName: "Show Legend",
            type: "boolean",
            transformFrom: () => true,
        },
        {
            name: "position",
            type: generateEnum(["top", "left", "bottom", "right"]),
            transformFrom: () => "top",
        },
        {
            name: "align",
            type: generateEnum(["start", "center", "end"]),
            transformFrom: () => "center",
        },
        // {
        //   name: "maxHeight",
        //   displayName: "Max Height",
        //   type: "number"
        // },
        // {
        //   name: "maxWidth",
        //   displayName: "Max Width",
        //   type: "number"
        // },
        {
            name: "fullSize",
            displayName: "Full Size",
            type: "boolean",
        },
        {
            name: "reverse",
            displayName: "Reverse",
            type: "boolean",
        },
        {
            name: "rtl",
            displayName: "Right to Left",
            type: "boolean",
        },
        {
            name: "textDirection",
            displayName: "Text Direction",
            type: generateEnum(["ltr", "rtl"]),
            transformFrom: () => "ltr",
        },
    ],
};
const legendLabelsOptions = {
    group: "Legend Labels",
    namespace: "plugins.legend.labels",
    options: [
        { name: "boxWidth" },
        { name: "size" },
        { name: "padding" },
        { name: "color", displayName: "Text Color", type: "color" },
        {
            name: "textAlign",
            displayName: "Text Align",
            type: generateEnum(["start", "center", "end"]),
            transformFrom: () => "center",
        },
        {
            name: "usePointStyle",
            displayName: "Use Point Style",
            type: "boolean",
            transformFrom: () => false,
        },
        {
            name: "pointStyle",
            displayName: "Point Style",
            type: generateEnum([
                "circle",
                "cross",
                "crossRot",
                "dash",
                "line",
                "rect",
                "rectRounded",
                "rectRot",
                "star",
                "triangle",
            ]),
            transformFrom: () => "circle",
        },
    ],
};
const legendLabelsFontOptions = {
    group: "Legend Labels Font",
    namespace: "plugins.legend.labels.font",
    options: [
        {
            name: "family",
            displayName: "Font Family",
            type: "font",
            transformTo: toNoodlFontClass,
        },
        {
            name: "size",
            displayName: "Font Size",
            type: "number",
            transformFrom: () => 12,
        },
        {
            name: "style",
            displayName: "Font Style",
            type: generateEnum(["normal", "italic", "oblique", "initial"]),
            transformFrom: () => "normal",
        },
        {
            name: "weight",
            displayName: "Weight",
            type: generateEnum(["normal", "italic", "oblique", "initial"]),
            transformFrom: () => "normal",
        },
        {
            name: "lineHeight",
            displayName: "Line Height",
            type: "number",
            transformFrom: () => 1.2,
        },
    ],
};
export const chart_inputs = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, generateInputs(globalOptions)), generateInputs(titleOptions)), generateInputs(titleFontOptions)), generateInputs(tooltipsOptions)), generateInputs(tooltipsFontOptions)), generateInputs(legendOptions)), generateInputs(legendLabelsOptions)), generateInputs(legendLabelsFontOptions)), { 
    // Allow to set custom plugins
    customPlugins: {
        group: "Advanced",
        displayName: "Plugins Object",
        type: "object"
    } });
export const chart_changed = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, generateInputsChanged(globalOptions)), generateInputsChanged(titleOptions)), generateInputsChanged(titleFontOptions)), generateInputsChanged(tooltipsOptions)), generateInputsChanged(tooltipsFontOptions)), generateInputsChanged(legendOptions)), generateInputsChanged(legendLabelsOptions)), generateInputsChanged(legendLabelsFontOptions)), { customPlugins(value) {
        if (!this.chart)
            return;
        if (typeof value === 'object') {
            Object.keys(value).forEach((key) => {
                this.chart.options.plugins[key] = value[key];
            });
            this.chart.update();
        }
    } });
export const chart_options = [
    globalOptions,
    titleOptions,
    titleFontOptions,
    tooltipsOptions,
    tooltipsFontOptions,
    legendOptions,
    legendLabelsOptions,
    legendLabelsFontOptions,
];
